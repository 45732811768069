import React, { useEffect, useState } from "react"
import { Typography, Grid } from "@material-ui/core"
import { makeComponentStyles } from "@components/mixins"
import Button from "@components/Buttons/Button.jsx"
import Modal from "@components/Dialog/Modal.jsx"
import Alert from "@components/Dialog/Alert"
import { FormGroup, FormControl, Form, FormFeedback, Label } from "@components/Forms"
import required from "@rules/required.js"
import email from "@rules/email.js"
import moreThanDate from "@rules/moreThanDate.js"
import rule from "@validator/rule"
import { encodeTimestamp } from "@src/heplers"
import { INSURANCE_ORDER } from "@api/modules/requests"
import { useIntl } from "gatsby-plugin-react-intl"

const ModalStyles = () => ({
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "15px"
  },
  btn_submit: {
    marginTop: 25
  }
})

const initBody = {
  name: "",
  surname: "",
  gender: "",
  citizenship: "",
  passport_num: "",
  started_passport: "",
  finished_passport: "",
  phone: "",
  email: "",
  room_num: ""
}

const ModalInsurance = ({ state, setState }) => {
  const {
    form,
    btn_submit
  } = makeComponentStyles(ModalStyles)

  const intl = useIntl()
  const [body, setBody] = useState(initBody)
  const [alertState, setAlertState] = useState(false)
  const observeBody = (key, event) => {
    const getTargetValue = () => {
      switch (event.target.type) {
        default:
          return event.target.value
      }
    }
    setBody(body => ({
      ...body,
      [key]: getTargetValue()
    }))
  }

  useEffect(() => {
    if (!alertState) setState(false)
  }, [alertState])

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      const {
        name,
        surname,
        gender,
        citizenship,
        passport_num,
        started_passport,
        finished_passport,
        phone,
        email,
        room_num,
        ...rest
      } = body;
      const formBody = {
        ...rest,
        user: {
          name: name + " " + surname,
          email: email,
          // phone: phone,
        },
        main: {
          room: room_num,
          gender: gender,
          passport: {
            citizenship: citizenship,
            number: passport_num,
            started_at: started_passport,
            finished_at: finished_passport,
          }
        }
      }
      const { data } = await INSURANCE_ORDER(formBody);
      setAlertState(true)
    } catch (err) {console.log(err.response)}
  }

  return (
    <>
      <Modal
        title={intl.formatMessage({ id: "insurance_form" })}
        state={state}
        setState={setState}
        width="md"
      >
        <Form validate onSubmit={handleSubmit} className={form}>
          <Grid container spacing={2}>
            {/*<<<<<<<<<<<<<<<< Имя >>>>>>>>>>>>>>>>>>>>>*/}
            <Grid item md={4} xs={12}>
              <FormGroup name={"name-input"}>
                <Label>{intl.formatMessage({ id: "name" })}</Label>
                <FormControl
                  onChange={observeBody.bind(null, "name")}
                  validations={[required]}
                />
                <FormFeedback />
              </FormGroup>
            </Grid>
            {/*<<<<<<<<<<<<<<<< Фамилия >>>>>>>>>>>>>>>>>>>>>*/}
            <Grid item md={4} xs={12}>
              <FormGroup name={"surname-input"}>
                <Label>{intl.formatMessage({ id: "surname" })}</Label>
                <FormControl
                  onChange={observeBody.bind(null, "surname")}
                  validations={[required]}
                />
                <FormFeedback />
              </FormGroup>
            </Grid>
            {/*<<<<<<<<<<<<<<<< Пол >>>>>>>>>>>>>>>>>>>>>*/}
            <Grid item md={4} xs={12}>
              <FormGroup name={"gender-input"}>
                <Label>{intl.formatMessage({ id: "gender" })}</Label>
                <FormControl
                  type={"select"}
                  onChange={observeBody.bind(null, "gender")}
                  validations={[required]}
                >
                  <option value="Выберите из списка">{intl.formatMessage({ id: "choose_list" })}</option>
                  <option value="male">{intl.formatMessage({ id: "gender_male" })}</option>
                  <option value="female">{intl.formatMessage({ id: "gender_female" })}</option>
                </FormControl>
                <FormFeedback />
              </FormGroup>
            </Grid>
            {/*<<<<<<<<<<<<<<<< Гражданство >>>>>>>>>>>>>>>>>>>>>*/}
            <Grid item md={4} xs={12}>
              <FormGroup name={"citizenship-input"}>
                <Label>{intl.formatMessage({ id: "citizenship" })}</Label>
                <FormControl
                  onChange={observeBody.bind(null, "citizenship")}
                  validations={[required]}
                />
                <FormFeedback />
              </FormGroup>
            </Grid>
            {/*<<<<<<<<<<<<<<<< Номер паспорта >>>>>>>>>>>>>>>>>>>>>*/}
            <Grid item md={4} xs={12}>
              <FormGroup name={"passport-num-input"}>
                <Label>{intl.formatMessage({ id: "passport_num" })}</Label>
                <FormControl
                  onChange={observeBody.bind(null, "passport_num")}
                  validations={[required]}
                />
                <FormFeedback />
              </FormGroup>
            </Grid>
            {/*<<<<<<<<<<<<<<<< Дата выдачи паспорта >>>>>>>>>>>>>>>>>>>>>*/}
            <Grid item md={4} xs={12}>
              <FormGroup name={"started-passport-input"}>
                <Label>{intl.formatMessage({ id: "started_passport" })}</Label>
                <FormControl
                  type={"date"}
                  onChange={observeBody.bind(null, "started_passport")}
                  validations={[
                    required
                    // rule(moreThanDate, { value: encodeTimestamp(body.started_at), message: intl.formatMessage({id: "arrival_finish_err"}) })
                  ]}
                />
                <FormFeedback />
              </FormGroup>
            </Grid>
            {/*<<<<<<<<<<<<<<<< Дата окончания паспорта >>>>>>>>>>>>>>>>>>>>>*/}
            <Grid item md={4} xs={12}>
              <FormGroup name={"finished-passport-input"}>
                <Label>{intl.formatMessage({ id: "finished_passport" })}</Label>
                <FormControl
                  type={"date"}
                  onChange={observeBody.bind(null, "finished_passport")}
                  validations={[
                    required,
                    rule(moreThanDate, {
                      value: encodeTimestamp(body.started_passport),
                      message: intl.formatMessage({ id: "finished_passport_err" })
                    })
                  ]}
                />
                <FormFeedback />
              </FormGroup>
            </Grid>
            {/*<<<<<<<<<<<<<<<< Номер телефона >>>>>>>>>>>>>>>>>>>>>*/}
            <Grid item md={4} xs={12}>
              <FormGroup name={"phone-input"}>
                <Label>{intl.formatMessage({ id: "phone_num" })}</Label>
                <FormControl
                  type="tel"
                  mask={"+7(999)-999-99-99"}
                  onChange={observeBody.bind(null, "phone")}
                  validations={[required]}
                />
                <FormFeedback />
              </FormGroup>
            </Grid>
            {/*<<<<<<<<<<<<<<<< Email >>>>>>>>>>>>>>>>>>>>>*/}
            <Grid item md={4} xs={12}>
              <FormGroup name={"email-input"}>
                <Label>{intl.formatMessage({id: "email_h"})}</Label>
                <FormControl
                  type="email"
                  onChange={observeBody.bind(null, "email")}
                  validations={[required, email]}
                />
                <FormFeedback/>
              </FormGroup>
            </Grid>
            {/*<<<<<<<<<<<<<<<< Номер комнаты СЖК >>>>>>>>>>>>>>>>>>>>>*/}
            <Grid item md={4} xs={12}>
              <FormGroup name={"room-num-input"}>
                <Label>{intl.formatMessage({ id: "room_num" })}</Label>
                <FormControl
                  onChange={observeBody.bind(null, "room_num")}
                  validations={[required]}
                />
                <FormFeedback />
              </FormGroup>
            </Grid>
          </Grid>
          <Button type="submit" color={"primary"} className={btn_submit}>{intl.formatMessage({ id: "send" })}</Button>
        </Form>
      </Modal>
      <Alert
        state={alertState}
        setState={setAlertState}
      >
        {intl.formatMessage({ id: "application_send" })}
      </Alert>
    </>
  )
}

export default ModalInsurance
