import React, { useContext, useState } from "react"
import Layout from "@components/Layout/Layout";
import Container from "@components/Container/Container.jsx";
import MainWrap from "@components/Wrap/MainWrap";
import { Typography } from "@material-ui/core";
import { Grid, CardMedia } from "@material-ui/core";
import { makeComponentStyles } from "@components/mixins.js"
import Button from "@components/Buttons/Button"
import { graphql } from "gatsby";
import Markdown from 'markdown-to-jsx';
import ButtonLink from "@components/Buttons/ButtonLink.jsx"
import { IntlContextConsumer, useIntl } from "gatsby-plugin-react-intl"
import { getStrapiContentByLang } from "../heplers.js"
import ModalInsurance from "@components/ModalInsurance/ModalInsurance"
import FloatWrap from "@components/Wrap/FloatWrap.jsx"
import Seo from "@components/seo"

const styles = ({ palette, breakpoints }) => ({
  text: {
    fontSize: "18px",
    color: palette.text.primary,
    '& $b': {
      fontWeight: 500
    },
    [breakpoints.down('sm')]: {
      fontSize: "14px",
      color: palette.text.black
    }
  },
  linktext: {
    display: 'contents',
    color: palette.primary.main,
    fontSize: "18px !important",
    [breakpoints.down('sm')]: {
      fontSize: "14px !important",
    }
  },
  visible: {
    [breakpoints.down('sm')]: {
      display: "none"
    }
  },
  form__btn: {
    width: "182px",
    height: "50px",
    fontSize: "18px !important",
    lineHeight: "21px !important",
    fontWeight: "normal !important",
  },
});

function go(link) {
  window.open(link, '_blank');
}

const Medical_insurance = ({ data }) => {
  const {
    text,
    linktext,
    visible,
    form__btn,
  } = makeComponentStyles(styles);

  const intl = useIntl();
  const { language } = useContext(IntlContextConsumer);
  const {
    head,
    image,
    CompanyTitle,
    Insurance,
    appendix,
    MedicalInsuranceSEO,
  } = getStrapiContentByLang(data.allStrapiMedicalInsurance.edges, language);

  const [modalInsuranceState, setModalInsuranceState] = useState(false);

  return (
    <div>
      <Seo title={MedicalInsuranceSEO?.TitleSEO}
        description={MedicalInsuranceSEO?.DescriptionSEO}
        keywords={MedicalInsuranceSEO?.KeywordsSEO.split(',')}
      />

      <Layout>
        <Container>
          <MainWrap>
            <MainWrap.Header>
              <Typography component={"h1"} variant={"h2"}>
                {intl.formatMessage({ id: 'medical_insurance' })}
              </Typography>
            </MainWrap.Header>
            <MainWrap.Body>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FloatWrap className={text} Img={image.localFile.publicURL} Float='right' displayNone={true}>
                    <Markdown>{head}</Markdown>
                  </FloatWrap>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={text}>
                    <Markdown style={{ listStylePosition: 'inside' }} >{CompanyTitle}</Markdown>
                  </Typography>
                  <div style={{ margin: '18px 0' }}>
                    {
                      Insurance.map(({ Company, Url, Description }, index) => (
                        <Typography className={text}>
                          <span style={{ fontWeight: 500 }}>{index + 1}. </span>
                          <ButtonLink className={linktext} onClick={() => go(Url)}>{Company}</ButtonLink>
                          {`, ${Description}`}
                        </Typography>
                      ))
                    }
                  </div>
                  <Typography className={text}>
                    <Markdown style={{ listStylePosition: 'inside' }} >{appendix}</Markdown>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent="center" alignItems="center" style={{ paddingTop: "50px" }}>
                <Button className={form__btn} style={{ padding: "5px" }} onClick={() => setModalInsuranceState(true)} type={"primary"} >{intl.formatMessage({ id: "insurance_btn" })}</Button>
                <ModalInsurance state={modalInsuranceState} setState={setModalInsuranceState}></ModalInsurance>
              </Grid>
            </MainWrap.Body>
          </MainWrap>
        </Container>
      </Layout>
    </div>
  )
}

export default Medical_insurance;
export const pageQuery = graphql`{
  allStrapiMedicalInsurance {
    edges {
      node {
        locale
        head
        image {
          localFile {
            publicURL
          }
        }
        CompanyTitle
        Insurance {
          Company
          Description
          Url
        }
        appendix
        MedicalInsuranceSEO {
          DescriptionSEO
          KeywordsSEO
          TitleSEO
        } 
      }
    }
  }
}`
